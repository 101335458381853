import { actions as authActions} from 'features/Auth/redux'

let timerWorks = true;
let timerCounter = timer(); 

function clearTimer() {
  clearInterval(timerCounter)
  resetStartTime()
  timerWorks = false;
  window.localStorage.setItem('inactivityTime', 0);
}
function resetStartTime() {
  const startTime = new Date()
  window.localStorage.setItem('startTime', startTime)
  window.localStorage.setItem('inactivityTime', 0)
}

function timer() {
  const startTime = new Date(window.localStorage.getItem('startTime') || resetStartTime())
  let timerTick = window.setInterval(function() {
    var secsDiff = new Date().getTime() - startTime.getTime()
    window.localStorage.setItem('inactivityTime', Math.floor(secsDiff / 1000))
  }, 1000)
  return timerTick
};

const actionFilter = store => {
  return next => {
    return action => {
      const result = next(action);
      const {isAuth, role} = store.getState().auth;
      const unActivityTime = window.localStorage.getItem('inactivityTime');
      if (result.type !== "auth/GET_BALANCE" || result.type === "auth/LOGOUT") {
        clearTimer()
      } 
      if (role === 1 && unActivityTime >= 1200 || role === 2 && unActivityTime >= 600) {
        window.localStorage.removeItem('startItem')
        window.localStorage.removeItem('inactivityTime')
        clearTimer(timerCounter);
        store.dispatch(authActions.logOut())
      } else {
        if (!isAuth && unActivityTime !== 0) clearTimer();
      }
      if (isAuth && !timerWorks) {
        timerWorks = true;
        timerCounter = timer()
      }
    }
  }
}

export { actionFilter };