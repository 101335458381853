export class UserApiConverter {

  convertUsersList = data => ({
    users: (data?.users || []).sort((a, b) => a.balance < b.balance ? 1 : -1),
    quantity: data?.quantity || 0,
    totalBalance: data?.balance_sum || 0,
  });

  convertNewPassword = data => data.new_password;
}
