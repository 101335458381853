import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';

import logo from './img/spinner.png';
import './Spinner.scss';

export const Spinner = ({ isLoading, size = 'default', isRoulette = false }) => {
  const b = block('spinner');
  return isLoading ? (
    <div className={b('wrapper', { loading: isLoading })}>
      <img src={logo} className={b('logo')}  alt='' />
    </div>
  ) : (
    ''
  );
};

Spinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isRoulette: PropTypes.bool,
  size: PropTypes.string,
};
